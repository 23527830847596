// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Component
import ModalAlert from "views/components/Alert/ModalAlert";
import { Grid } from '@mui/material';
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { styled } from "@mui/material/styles";

// CSS
import modalStyles from "assets/css/Modal.module.css";
import buttonStyle from "assets/css/Button.module.css";

const Alert = ({ alertData, setAlertData }) => {
    // const history = useHistory();

    const onShow = (val) => {
        setBodyScroll(val);

        setAlertData({
            ...alertData, show: val,
            confirmStatus: false,
            cancelStatus: false
        });
    };

    const renderSVGAlert = (status) => {
        if (status === "success") {
            return (
                <div className="svg-box">
                    <svg className="circular green-stroke">
                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                    </svg>
                    <svg className="checkmark green-stroke">
                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                        </g>
                    </svg>
                </div>
            );
        }
        else if (status === "warning") {
            return (
                <div className="svg-box">
                    <svg className="circular yellow-stroke">
                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                    </svg>
                    <svg className="alert-sign yellow-stroke">
                        <g transform="matrix(1,0,0,1,-615.516,-257.346)">
                            <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                                <path className="line" d="M634.087,300.805L673.361,261.53" fill="none" />
                            </g>
                            <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                                <circle className="dot" cx="621.52" cy="316.126" r="1.318" />
                            </g>
                        </g>
                    </svg>
                </div>
            );
        }
        else if (status === "error") {
            return (
                <div className="svg-box">
                    <svg className="circular red-stroke">
                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                    </svg>
                    <svg className="cross red-stroke">
                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                            <path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none" />
                        </g>
                        <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                            <path className="second-line" d="M634.087,300.805L673.361,261.53" />
                        </g>
                    </svg>
                </div>
            );
        }

        return null;
    };

    const confirmStatus = () => {
        setAlertData({
            ...alertData, show: false,
            confirmStatus: true,
            cancelStatus: false
        });
    };

    const renderButton = (action) => {
        if (action === undefined || action === null) return null;

        if (action === "sayYes") {
            return (
                <div className={modalStyles.boxMarginFormTop}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className="center">
                                <div className={[buttonStyle.btn, buttonStyle.btnCancel, buttonStyle.btnRadius].join(" ")} onClick={() => onShow(false)}>
                                    {alertData.cancelText}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="center">
                                <div className={[buttonStyle.btn, buttonStyle.btnConfirm, buttonStyle.btnRadius].join(" ")} onClick={confirmStatus}>
                                    {alertData.confirmText}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className={modalStyles.boxMarginFormTop}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                            <div className="center">
                                <button className={[buttonStyle.btn, buttonStyle.btnConfirm, buttonStyle.btnSizeMedium, buttonStyle.btnRadius].join(" ")} onClick={() => onShow(false)}>
                                    {alertData.confirmText}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    };

    // if (alertData !== null) {
    // const mess = alertData.message.split("<br>");
    // const messArr = mess.map((item) => (item.split(" ")));
    // }

    // const StyleTableCell = styled(TableCell)(({ theme }) => ({
    //     [`&.${tableCellClasses.body}`]: {
    //         fontSize: 16,
    //         color: "#fff"
    //     }
    // }));

    // const StyleTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: "#2F3642",
    //     },
    //      // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // }));

    // const alertCut = (message) => {
    //     if (message !== null && message !== "") {
    //         const ms = message.split("<br>").map((item) => item.split(" - "));
    //         // message.pop();
    //         return ms;
    //     }

    // };

    // if (alertData !== null) {
    const data = alertData.message.split("<br>").map((item) => item.split(" - "));

    // }

    return (
        <ModalAlert show={alertData.show} alertData={alertData} onShow={onShow}>
            <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                {alertData.title}
            </div>

            {
                (alertData.status !== null && alertData.status !== "") && (
                    <Grid container justifyContent="center">
                        <Grid item>
                            {
                                renderSVGAlert(alertData.status)
                            }
                        </Grid>
                    </Grid>
                )
            }

            {
                alertData.type === "htmlUpdate" && (
                    <div className={modalStyles.confirmParagrap}>
                        <div className={modalStyles.htmlUpdate}>
                            {

                                data.slice(0, data.length - 1).map((list, index) => (
                                    <div className={modalStyles.htmlUpdateList}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <div dangerouslySetInnerHTML={{ __html: list[0] }}></div>
                                            </Grid>
                                            <Grid item container direction="row">
                                                <Grid item xs={8}>
                                                    <div dangerouslySetInnerHTML={{ __html: list[1] }}></div>
                                                </Grid>
                                                <Grid item xs={4} textAlign="right">
                                                    <div dangerouslySetInnerHTML={{ __html: list[2] }}></div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }

            {
                alertData.type === "html" && (
                    <div className={[modalStyles.confirmParagrap, "center"].join(" ")}>
                        <div dangerouslySetInnerHTML={{ __html: alertData.message }}></div>
                    </div>
                )
            }

            {
                (alertData.type !== "html" && alertData.type !== "htmlUpdate") && (
                    <div className={[modalStyles.confirmParagrap, "center"].join(" ")}>
                        {alertData.message}
                    </div>
                )
            }


            <br />

            {
                renderButton(alertData.action)
            }
        </ModalAlert>
    );
}

export default Alert;