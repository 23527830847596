import { useState, useEffect } from 'react';
import moment from 'moment';

// Component
import Grid from '@mui/material/Grid';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';

// CSS
// import styles from "assets/css/Style.module.css";
// import rewardStyle from "views/pages/reward/assets/Reward.module.css";

export default function Lsit({ dataList, selectedDate }) {
    // console.log(dataList)

    const [yikiRound, setYikiRound] = useState();
    const [thaiRound, setThaiRound] = useState();
    const [hunRound, setHunRound] = useState();
    const [bankRound, setBankRound] = useState();
    const [hanoyRound, setHanoyRound] = useState();
    const [malayRound, setMalayRound] = useState();

    const lottoCode = (val) => {
        if (val === "HT") {
            return "images/icon/thaigov.webp"
        } else if (val === "HL") {
            return "images/icon/laoscircle.webp"
        } else if (val === "HM") {
            return "images/icon/malaycircle.webp"
        } else if (val === "HH") {
            return "images/icon/hanoycircle.webp"
        } else {
            return "images/icon/yikicircle.webp"
        }
    }

    const iconCode = (val) => {
        if (val === "HSTL" || val === "thai") {
            return "images/icon/thaigov.webp"       // ไทย
        } else if (val === "HSGM") {
            return "images/icon/german.webp"        // เยอร์มัน
        } else if (val === "HSRS") {
            return "images/icon/russia.webp"        // รัสเซีย
        } else if (val === "HSEY") {
            return "images/icon/egypt.webp"         // อียิปต์
        } else if (val === "HSSP") {
            return "images/icon/singapore.webp"     // สิงคโปร์
        } else if (val === "HSDJ") {
            return "images/icon/downjones.webp"     // เมกา - ดาวน์โจนส์
        } else if (val === "HSTW") {
            return "images/icon/taiwan.webp"        // ไต้หวัน
        } else if (val === "HSEN") {
            return "images/icon/england.webp"       // อังกฤษ
        } else if (val === "HSID") {
            return "images/icon/india.webp"         // อินเดีย
        } else if (val === "HSCN") {
            return "images/icon/chinacircle.webp"   // จีน
        } else if (val === "HSHK") {
            return "images/icon/hongkong.webp"      // ฮ่องกง - ฮั่งเส็ง
        } else if (val === "HSJP") {
            return "images/icon/japan.webp"         // ญี่ปุ่น - นิเคอิ
        } else if (val === "HSKR") {
            return "images/icon/korea.webp"         // เกาหลี
        } else if (val === "HBBA") {
            return "images/icon/baac.webp"          // ธกส
        } else if (val === "HBGS") {
            return "images/icon/gsb.webp"           // ออมสิน
        } else if (val === "hanoy") {
            return "images/icon/hanoycircle.webp"   // ฮานอย - ฮานอย พิเศษ - ฮานอย VIP
        } else if (val === "malay") {
            return "images/icon/malaycircle.webp"   // มาเลย์
        }
    }

    // const isEqual = (ar1, ar2) => {
    //     const ss = ar1.map(item => (item === ar2.map(item2 => item2)))
    //     const s1 = ar1.map(item => item)
    //     const s2 = ar2.map(item => item)

    //     const s11 = ar1.map((item, index) => (item, index))

    //     // console.log("s1 --> ", s1)
    //     // console.log("s2 --> ", s2)
    //     let s1d = []
    //     let s2d = []
        
    //     for (let x = 0; x <= s1.length-1; x++) {
    //         s1d.push({"id": x+1, "code": s1[x], "status": "null"})
    //     }
    //     // console.log(s1d)

    //     for (let i = 0; i <= s1.length - 1; i++) {
    //         for (let j = 0; j <= s2.length; j++) {
    //             if (s2[j] === s1[i]) {
    //                 // s1d.push({"id":i, "code":s1.slice(0, s1[i].length)})
    //                 s2d.push({"id":i+1, "code":s1[i], "status": "exist"})
    //                 // return s1d
    //                 // console.log(s1[i])
    //                 // return s1[i]
    //             }
    //         }
            
    //     }
    //     // s1d.length = s1d.length - s2d.length
    //     // const sfn = s1d.concat(s2d)

    //     // const news1d = s1d.map(item => {
    //     //     // for (let y = 0; y <= s1d.length-1; y++) {
    //     //         if (item.id === s2d.map(item2 => item2.id)) {
    //     //             return {...item, status: "exist"}
    //     //         }
    //     //     // }
            
    //     //     return item
    //     // })
    //     console.log(s1d);
    //     // return news1d;
    //     // if (ss) {
    //     //     console.log(ss)
    //     //     console.log("-> yes")
    //     // } else {
    //     //     console.log(ss)
    //     //     console.log("---> no")
    //     // }
    // };

    useEffect(() => {
        if (dataList !== null) {
            
            setYikiRound(dataList.filter(item => item.lottery_code.split("").slice(0,3).join("") === "HYK").sort((a,b) => (a.lottery_code.split("").slice(3).join("") - b.lottery_code.split("").slice(3).join(""))));
            setThaiRound(dataList.filter(item => item.lottery_code.split("").slice(0,2).join("") === "HT"))
            setHunRound(dataList.filter(item => item.lottery_code.split("").slice(0,2).join("") === "HS"))
            setBankRound(dataList.filter(item => item.lottery_code.split("").slice(0,2).join("") === "HB"))
            setHanoyRound(dataList.filter(item => item.lottery_code.split("").slice(0,3).join("") === "HHN"))
            setMalayRound(dataList.filter(item => item.lottery_code.split("").slice(0,3).join("") === "HML"))

            // console.log("yiki -> ", yikiRound)
            // console.log("thai -> ", thaiRound)
            // console.log("hun -> ", hunRound)
            // console.log("bank -> ", bankRound)
            // console.log("hanoy-> ", hanoyRound)
            // console.log("malay -> ", malayRound)
            // console.log(selectedDate)

        }
    }, [dataList, selectedDate]);
    
    
    return (
        <>
        
            {
                dataList !== null && (thaiRound !== null && thaiRound !== undefined && thaiRound !== [] && thaiRound.length > 0) ?
                <>
                    {
                        thaiRound.map((item, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                                <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                                    <Grid
                                        container 
                                        direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        style={{ 
                                            color: "var(--bg-white)",
                                            padding: "15px 20px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Grid item container xs={8} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={iconCode("thai")}
                                                    alt={item.lottery_name}
                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                { item.lottery_name }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            { selectedDate }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px" }}>
                                    <TableContainer>
                                        <Table size="small" sx={{ minWidth: "100%", borderRadius: "0px 0px 10px 10px" }}>
                                            <TableBody>
                                                
                                                {
                                                    item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                    item?.lottory_main.map((item2, index2) => (
                                                        <TableRow key={index2}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item2.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ minWidth: "100px", fontWeight: "bold", fontSize: "15px" }}>
                                                                { item2.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                {
                                                    item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                    item?.lottory_sub.map((item3, index3) => (
                                                        <TableRow key={index3}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item3.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                { item3.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                <></>
            }

            {
                dataList !== null && (bankRound !== null && bankRound !== undefined && bankRound !== [] && bankRound.length > 0) ?
                <>
                    {
                        bankRound.map((item, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                                <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                                    <Grid
                                        container 
                                        direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        style={{ 
                                            color: "var(--bg-white)",
                                            padding: "15px 20px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Grid item container xs={8} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={iconCode(item.lottery_code.split("").splice(0,4).join(""))}
                                                    alt={item.lottery_name}
                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                { item.lottery_name }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            { selectedDate }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px" }}>
                                    <TableContainer>
                                        <Table size="small" sx={{ minWidth: "100%", borderRadius: "0px 0px 10px 10px" }}>
                                            <TableBody>
                                                
                                                {
                                                    item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                    item?.lottory_main.map((item2, index2) => (
                                                        <TableRow key={index2}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item2.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ minWidth: "100px", fontWeight: "bold", fontSize: "15px" }}>
                                                                { item2.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                {
                                                    item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                    item?.lottory_sub.map((item3, index3) => (
                                                        <TableRow key={index3}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item3.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                { item3.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))
                    }
                </>

                :
                <></>
            }

            {
                dataList !== null && (hanoyRound !== null && hanoyRound !== undefined && hanoyRound !== [] && hanoyRound.length > 0) ?
                <>
                    {
                        hanoyRound.map((item, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                                <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                                    <Grid
                                        container 
                                        direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        style={{ 
                                            color: "var(--bg-white)",
                                            padding: "15px 20px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Grid item container xs={8} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={iconCode("hanoy")}
                                                    alt={item.lottery_name}
                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                { item.lottery_name }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            { selectedDate }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px" }}>
                                    <TableContainer>
                                        <Table size="small" sx={{ minWidth: "100%", borderRadius: "0px 0px 10px 10px" }}>
                                            <TableBody>
                                                
                                                {
                                                    item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                    item?.lottory_main.map((item2, index2) => (
                                                        <TableRow key={index2}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item2.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ minWidth: "100px", fontWeight: "bold", fontSize: "15px" }}>
                                                                { item2.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                {
                                                    item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                    item?.lottory_sub.map((item3, index3) => (
                                                        <TableRow key={index3}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item3.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                { item3.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))
                    }
                </>

                :
                <></>
            }

{
                dataList !== null && (malayRound !== null && malayRound !== undefined && malayRound !== [] && malayRound.length > 0) ?
                <>
                    {
                        malayRound.map((item, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                                <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                                    <Grid
                                        container 
                                        direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        style={{ 
                                            color: "var(--bg-white)",
                                            padding: "15px 20px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Grid item container xs={8} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={iconCode("malay")}
                                                    alt={item.lottery_name}
                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                { item.lottery_name }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            { selectedDate }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px" }}>
                                    <TableContainer>
                                        <Table size="small" sx={{ minWidth: "100%", borderRadius: "0px 0px 10px 10px" }}>
                                            <TableBody>
                                                
                                                {
                                                    item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                    item?.lottory_main.map((item2, index2) => (
                                                        <TableRow key={index2}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item2.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ minWidth: "100px", fontWeight: "bold", fontSize: "15px" }}>
                                                                { item2.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                {
                                                    item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                    item?.lottory_sub.map((item3, index3) => (
                                                        <TableRow key={index3}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item3.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                { item3.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))
                    }
                </>

                :
                <></>
            }
            
            {
                dataList !== null && (hunRound !== null && hunRound !== undefined && hunRound !== [] && hunRound.length > 0) ?
                    <div style={{ marginTop: "20px" }}>
                        <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                            <Grid
                                container 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center" 
                                style={{ 
                                    color: "var(--bg-white)",
                                    padding: "15px 20px",
                                    fontWeight: "bold"
                                }}
                            >
                                <Grid item container xs={8} sm={9} spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        <FlagIcon sx={{ color: "var(--bg-sucess)", verticalAlign: "middle" }}></FlagIcon>
                                    </Grid>
                                    <Grid item>
                                        หวยหุ้นไทย/ต่างประเทศ
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    { selectedDate }
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px", paddingBottom: "15px" }}>
                            <TableContainer>
                                <Table size="small" sx={{ borderRadius: "0px 0px 10px 10px" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px", borderRight: "1px solid var(--bg-line)" }}>รอบหวย</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px", borderRight: "1px solid var(--bg-line)" }}>ดัชนี</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px" }}>CHANGE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataList !== null && (
                                                dataList.length > 0 ?
                                                hunRound?.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ borderRight: "1px solid var(--bg-line)" }}>
                                                            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                                                                <Grid item>
                                                                <img
                                                                    src={iconCode(item.lottery_code.split("").splice(0,4).join(""))}
                                                                    alt={item.lottery_name}
                                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                                />
                                                                </Grid>
                                                                <Grid item sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                    { item.lottery_name }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        {
                                                            item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                            item?.lottory_main?.map((item2, index2) => (
                                                                <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "14px", borderRight: index2 % 2 === 1 ? "" : "1px solid var(--bg-line)" }} key={index2}>
                                                                    { item2.number }
                                                                </TableCell>
                                                            ))
                                                            :
                                                            <>
                                                                <TableCell align="center" sx={{ borderRight: "1px solid var(--bg-line)" }}>
                                                                    <Chip label="รอผล" size="small" style={{ background: "var(--bg-blue)", color: "var(--bg-white)", fontWeight: "bold" }} />
                                                                </TableCell>
                                                            </>
                                                        }

                                                        {
                                                            item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                            item?.lottory_sub?.map((item3, index3) => (
                                                                <TableCell align="center" key={index3} sx={{ fontWeight: "bold", fontSize: "14px" }}>
                                                                    { item3.number }
                                                                </TableCell>
                                                            ))
                                                            :
                                                            <>
                                                                <TableCell align="center" sx={{ borderRight: "1px solid var(--bg-line)" }}>
                                                                    <Chip label="รอผล" size="small" style={{ background: "var(--bg-blue)", color: "var(--bg-white)", fontWeight: "bold" }} />
                                                                </TableCell>
                                                            </>
                                                        }

                                                    </TableRow>
                                                ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" sx={{ color: "var(--bg-grey)" }}>
                                                        ไม่มีข้อมูล
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                :
                <></>
            }
            
            {
                dataList !== null && (yikiRound !== null && yikiRound !== undefined && yikiRound !== [] && yikiRound.length > 0) ?
                    <div style={{ marginTop: "20px" }}>
                        
                        <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                            <Grid
                                container 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center" 
                                style={{ 
                                    color: "var(--bg-white)",
                                    padding: "15px 20px",
                                    fontWeight: "bold"
                                }}
                            >
                                <Grid item container xs={8} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        <FlagIcon sx={{ color: "var(--bg-sucess)", verticalAlign: "middle" }}></FlagIcon>
                                    </Grid>
                                    <Grid item>
                                        หวยจับยี่กี
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    { selectedDate }
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px", paddingBottom: "15px" }}>
                            <TableContainer>
                                <Table size="small" sx={{ borderRadius: "0px 0px 10px 10px" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px", borderRight: "1px solid var(--bg-line)" }}>รอบหวย</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px", borderRight: "1px solid var(--bg-line)" }}>3 ตัวบน</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: "bold", color: "var(--bg-brand)", fontSize: "15px" }}>2 ตัวล่าง</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataList !== null && (
                                                dataList.length > 0 ?
                                                yikiRound?.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ borderRight: "1px solid var(--bg-line)" }}>
                                                            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                                                                <Grid item>
                                                                    <img
                                                                        src={lottoCode(item.lottery_code.split("").splice(0,2).join(""))}
                                                                        alt={item.lottery_name}
                                                                        style={{ width: "23px", verticalAlign: "middle" }}
                                                                    />
                                                                </Grid>
                                                                <Grid item sx={{ fontWeight: "bold", fontSize: "14px" }}>
                                                                    { item.lottery_name }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        {
                                                            item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                            item?.lottory_sub?.map((item2, index2) => (
                                                                <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "14px", borderRight: index2 % 2 === 1 ? "" : "1px solid var(--bg-line)" }} key={index2}>
                                                                    { item2.number }
                                                                </TableCell>
                                                            ))
                                                            :
                                                            <>
                                                                <TableCell align="center" sx={{ borderRight: "1px solid var(--bg-line)" }}>
                                                                    <Chip label="รอผล" size="small" style={{ background: "var(--bg-blue)", color: "var(--bg-white)", fontWeight: "bold" }} />
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Chip label="รอผล" size="small" style={{ background: "var(--bg-blue)", color: "var(--bg-white)", fontWeight: "bold" }} />
                                                                </TableCell>
                                                            </>
                                                        }

                                                    </TableRow>
                                                ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" sx={{ color: "var(--bg-grey)" }}>
                                                        ไม่มีข้อมูล
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                :
                <></>
            }
            
        </>
    );
}