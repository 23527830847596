import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// @Utility
import timeZone from "@utility/app/timeZone";

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Redux Action
import { useDispatch, useSelector } from 'react-redux';
import { loadRewardList, loadRewardCode } from "@core/redux/actions/reward/index";

// Component
import {
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material';
// import CardContent from "views/components/CardContent";
import ReportDate from "views/pages/reward/component/ReportDate";
import ReportType from "views/pages/reward/component/ReportType";
import CardContent from 'views/components/CardContent';

// Icon
import DateRangeIcon from '@mui/icons-material/DateRange';
import ViewListIcon from '@mui/icons-material/ViewList';

// CSS
import styles from "assets/css/Style.module.css";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ContentReward() {
    const reduxReward = useSelector(state => state.reduxReward.result);
    const reduxRewardCode = useSelector(state => state.reduxReward.resultCode);
    const [value, setValue] = useState(0);
    const [dataList, setDataList] = useState(null);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const requestApi = (dataDate, loterryCode, limit = 100) => {
        let myJson = {}

        if(dataDate !== null) {
            let time_start = timeZone(`${dataDate} 00:00:00`, "noFormat");
            let time_end = timeZone(`${dataDate} 23:59:59`, "noFormat");

            myJson = {
                "filter": {
                    "loterry_code": loterryCode,
                    "time_start": time_start,
                    "time_end": time_end
                },
                "datatable": {
                    "limit": limit,
                    "offset": 0,
                    "sort": ""
                }
            };
        }
        else {
             myJson = {
                "filter": {
                    "loterry_code": loterryCode
                },
                "datatable": {
                    "limit": limit,
                    "offset": 0,
                    "sort": ""
                }
            };
        }

        dispatch(loadRewardList(myJson));
    };

    useEffect(() => {
        if(reduxReward !== null) {
            if(reduxReward?.data !== undefined && reduxReward?.data !== null) {
                setDataList(reduxReward?.data);
            }
            else {
                setDataList([]);
            }
        }
        else {
            setDataList([]);
        }
    }, [reduxReward]);

    useEffectOnce(() => {
        dispatch(loadRewardCode());
    }, []);

    return (
        <div className={styles.boxMarginTop}>
            <div className={styles.root}>
                <CardContent>
                    <Tabs
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        classes={{
                            indicator: styles.indicator
                        }}
                    >
                        <LinkTab label="ตามวันที่" className={value === 0 ? styles.tabTextActive : styles.tabText} icon={<DateRangeIcon className={styles.tabIconSize} />} {...a11yProps(0)} />
                        <LinkTab label="ตามประเภท" className={value === 1 ? styles.tabTextActive : styles.tabText} icon={<ViewListIcon className={styles.tabIconSize} />} {...a11yProps(1)} />
                    </Tabs>
                </CardContent>

                <TabPanel value={value} index={0} className={styles.tabContent}>
                    <ReportDate dataList={dataList} requestApi={requestApi} setDataList={setDataList} />
                </TabPanel>
                <TabPanel value={value} index={1} className={styles.tabContent}>
                    <ReportType dataList={dataList} requestApi={requestApi} setDataList={setDataList} reduxRewardCode={reduxRewardCode} />
                </TabPanel>
            </div>
        </div>
    );
}